.container {
    width: 100%;
    padding: 8px 16px;
    background: #AEFFAA;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    overflow: hidden;
}

.placeholder {
    width: 32px;
    height: 32px;
    position: relative;
}

.textContainer {
    flex: 1 1 0;
    text-align: center;
    color: black;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    word-wrap: break-word;
}

.closeButton {
    width: 32px;
    height: 32px;
    position: relative;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--colorGrey900);
    margin: 0;
}

.closeText {
    /* Hide the text but keep it accessible for screen readers */
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.closeIcon {
    width: 12px;
    height: 12px;
}

/* Mobile styles */
@media (max-width: 768px) {
    .textContainer {
        font-weight: 400;
    }
}